import React from 'react';


function About() {
  return (

    <div className="ui container">
    <div className="ui raised very padded text container segment" style={{margin:'80px'}}>

      <h3 className="ui header">
        About
      </h3>
      <p>
        In the domain of Artificial Intelligence x Education, we are making an App, which provides non-fiction books in Simplified English. The text simplification is driven by AI. It would enable you to read and understand, with more ease, a wide range of books, including science, engineering, social science, psychology, history and self-development. Ready to learn from the best authors / experts in the world?
      </p>



    </div>


    <div className="ui cards">

        <div class="ui card" style={{margin:"80px"}}>
          <div class="image">
            <img src=""/>
          </div>
          <div class="content">
            <a class="header">Valance WANG</a>
            <div class="description">
              Valance is the Chief Executive Officer of the company. She is of interdisciplinary origin, and has an MSc in Computational Neuroscience and an MPhil in Artificial Intelligence.
            </div>
            <div class="email">
              Email: valancewang at gmail dot com 
            </div>
          </div>
        </div>


        <div class="ui card" style={{margin:"80px"}}>
          <div class="image">
            <img src=""/>
          </div>
          <div class="content">
            <a class="header">Stefanie ZHAO</a>
            <div class="description">
              Stefanie is the Chief Technology Officer of the company. She is a PhD in Software Technology / Robotics - where she works on AI and Computer Vision. She also has 8+ years of experience in software engineering, and has led a team of engineers as the project manager.  
            </div>
          </div>
        </div>


        <div class="ui card" style={{margin:"80px"}}>
          <div class="image">
            <img src=""/>
          </div>
          <div class="content">
            <a class="header">Nico LI</a>
            <div class="description">
              Nico is the Product Manager in the company. She has rich experience in tech innovative products development from 0 to 1. 
            </div>
          </div>
        </div>




      </div>

    </div>
  );
}

export default About;