//import 'dotenv/config';
import React, { Component } from 'react';
import { BrowserRouter, Routes, Route} from 'react-router-dom';

import Home from './features/04-Home/Home';
import About from './features/02-About/About';
import Contact from './features/03-Contact/Contact';
import Library from './features/05-Library/Library';


import Header from './features/00-HeaderFooter/Header';
import Footer from './features/00-HeaderFooter/Footer';

import Book from './books/karmic-management';


class App extends Component {


  render() {
    return (

    <BrowserRouter>
    <div className="App">

      <Header />

      
      <Routes>
        <Route exact path="/"  element={<Home/>}    />
        <Route path="/library" element={<Library/>} />
        {/* <Route path="/book"    element={<Book/>} /> */}
        <Route path="/about"   element={<About/>}   />
        <Route path="/contact" element={<Contact/>} />
      </Routes>


      <Footer />
    </div>
    </BrowserRouter>

    );
  }
}



export default App;







