import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, Sidebar } from 'semantic-ui-react';


function Overlay() {
  return (
    <div style={{
      backgroundColor: "rgba(0, 0, 0, 0.795)",
      position: "fixed",
      height: "110vh",
      width: "100%",
    }} />
  )
}

function HamIcon() {
  return (<i className="big bars icon inverted" />)
}




function NavbarMd({openLogIn, openSignUp}) {

    const [visible, setVisible] = useState(false)
    
    const [icon, setIcon] = useState(HamIcon)
    
    const [activeItem, setactiveItem] = useState("home")
    
    const handleItemClick = (e, { name }) => setactiveItem(name)
    
    const hideSidebar = () => {
        setIcon(HamIcon)
        setVisible(false)
    }
    
    const showSidebar = () => {
        setVisible(true)
    }
    
    const toggleSidebar = () => {
        visible ? hideSidebar() : showSidebar()
    }
    
    return (
        <>
        {visible && <Overlay />}
        <Menu inverted
            size="tiny"
            borderless
            attached
        >
            {/* <Menu.Item>
            <img src="ghostblog.svg" width="35px" height="35px" alt="" />
            </Menu.Item> */}
            <Menu.Menu position='right'>
            <Menu.Item onClick={toggleSidebar}>
                {icon}
            </Menu.Item>
            </Menu.Menu>
        </Menu>
        <Sidebar.Pusher onClick={hideSidebar}>
        <Sidebar as={Menu}
            animation='overlay'
            icon='labeled'
            inverted
            vertical
            visible={visible}
            width={"200px"}
        >
            {/* <Menu.Item>
            <img src="ghostblog.svg" width="35px" height="35px" style={{ margin: "0 auto" }} alt="" />
            </Menu.Item> */}
            <Menu.Item
            name='home'
            active={activeItem === 'home'}
            onClick={handleItemClick}
            link={true}><NavLink to="/">Home</NavLink></Menu.Item>
            <Menu.Item
            name='library'
            active={activeItem === 'library'}
            onClick={handleItemClick}
            link={true}><NavLink to="/library">Library</NavLink></Menu.Item>
            <Menu.Item
            name='about'
            active={activeItem === 'about'}
            onClick={handleItemClick}
            link={true}><NavLink to="/about">About</NavLink></Menu.Item>
            <Menu.Item
            name='contact'
            active={activeItem === 'contact'}
            onClick={handleItemClick}
            link={true}><NavLink to="/contact">Contact</NavLink></Menu.Item>
            <Menu.Item
            name='login'
            active={activeItem === 'login'}
            onClick={openLogIn}
            link={true}
            />
            <Menu.Item
            name='signup'
            active={activeItem === 'signup'}
            onClick={openSignUp}
            link={true}
            />
        </Sidebar>
        </Sidebar.Pusher>
        </>
    )
}

export default NavbarMd;
