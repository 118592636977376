import React, { useState, useEffect }  from 'react';
import axios from 'axios';
import BookCard from './BookCard';
import library_books from '../../shared/library';
import { QUILL_PROJECT_API_URL } from '../../baseUrl';

  
function Library () {

	const [library, setLibrary] = useState(library_books);

	const URL = QUILL_PROJECT_API_URL + '/books/';

	const fetchInfo = async () => {
		try{
			var response = await axios.get(URL);
			let data = response.data.data.books;
			setLibrary(data);
		} catch (error) {
			console.error('Error', error);
		} 
	  };
	
	useEffect(() => {
		fetchInfo();
	  }, []);

	const book_list = library.map((book) => {
		return (
		  <div className="item" key={book.isbn}>     
			< BookCard 
			book_name={book.book_name} 
			book_description={book.description}
			book_url={book.url}
			book_img={book.image} 
			/>
		  </div>
		)
	  })
	





	return (

		<div className="pusher">
		<div className="ui container center">  
			<div className="ui list">
				
				
				{/* Beautification */}
				<div className="ui raised very padded text container segment" style={{margin:'80px'}}>

					<h3 className="ui header">
					Library
					</h3>
					<p>
					We are trying hard to get the copyright of the books...
					</p>

					</div>

				  {/* Beautification end */}
				{/* {book_list} */}
			</div>
			<div style={{marginBottom:"100px"}}></div>
		</div>
		</div>
	


		)
}

export default Library;