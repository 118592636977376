import React from 'react';
import {useForm} from 'react-hook-form';

function Contact() {

  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = data => console.log(data);




  return (
    <div className="pusher">
      <div className="ui raised very padded text container segment" style={{margin:'80px'}}>

        <h3 className="ui header">
          Contact
        </h3>
        <p>
          In building progress...
        </p>

      </div>

      <form className="ui form" style={{margin:'80px'}} onSubmit={handleSubmit(onSubmit)}>

          <h3 className="header">
            Contact card
          </h3>

          
          <div className="field">
            <label className="">Your name</label>
            <input placeholder="Your nickname" {...register("nickname")}/>
          </div>

          <div className="field">
            <label className="">Your email</label>
            <input placeholder='example@gmail.com' {...register("email")}/>
          </div>

          <div className="field">
            <label className="">Your comment</label>
            <textarea {...register("comment")}/>
          </div>        


        <button className="ui button" type="submit">Submit</button>

      </form>


    </div>
  );
}

export default Contact;