import React from 'react';
import { NavLink } from 'react-router-dom';

function Footer() {

	return (
		<div className="pusher" style={{backgroundColor:"#444444"}}>

	   		<div className="ui container">
			      <div className="ui large secondary inverted pointing menu">
			        <a className="toc item">
			          <i className="sidebar icon"></i>
			        </a>
					<a className="item"><NavLink to="/">Home</NavLink></a>
					<a className="item"><NavLink to="/library">Library</NavLink></a>
					<a className="item"><NavLink to="/about">About</NavLink></a>
					<a className="item"><NavLink to="/contact">Contact</NavLink></a>

			      </div>
				  <div className="ui large secondary inverted pointing menu item">
					QUILL PROJECT LIMITED
				  </div>
		    </div>
		</div>
);
}

export default Footer;