import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { useForm } from "react-hook-form";

import axios from 'axios';
import { QUILL_PROJECT_API_URL } from '../../baseUrl';

function SignUp ( { showSignUp, openSignUp, closeSignUp } ) {



    const [errorMessage, setErrorMessage] = useState("");

    const { register, handleSubmit, formState: { errors }, watch } = useForm();

    const onSubmit = async (formValues) => 
    {
        console.log('Submit');
        console.log(QUILL_PROJECT_API_URL + '/user/signup');
      try {
        const response = await axios.post(QUILL_PROJECT_API_URL + '/user/signup', formValues);
        console.log(response);
        
    } catch (error) {
  
        console.log(error);
  
        const errorCode = error.response.status;
        const message = error.response.data;
        setErrorMessage( message + '(Error code ' + errorCode.toString() + ')');
  
      }
      
    };
  
  


    



    return (

    <div className="ui container">
        <Modal size="mini" className="m-auto" centered={true}
        style={{maxHeight:"400px", left:"auto", right:"auto", top:"auto", bottom:"auto"}}
        open={showSignUp} onOpen={openSignUp} onClose={closeSignUp}>        
            <div className="header">
                Sign up
            </div>
            <div className="content">
                <form className="ui form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="field">
                        <label>Email</label>
                        <input id="email" type="email" name="email" placeholder="Email"
                        {...register("email", {required: true, pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,6}$/g})} />
                        {errors.email && <span className="text-red-500">Please enter your email address.</span>}
                    </div>
                    
                    <div className="field">
                        <label>Password</label>
                        <input type="password" name="password" placeholder="Password" autoComplete="on"
                        {...register("password", {required: true, pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{6,}$/ })} />
                        {errors.password && <span className="text-red-500">The password requires at least one letter, one digit. The minimum length is 6.</span>}
                    </div>
                    
                    <div className="field">
                        <label>Confirm password</label>
                        <input type="password" name="confirm-password" placeholder="Confirm password" autoComplete="on" 
                        {...register("confirmPassword", {required: true, validate: (value) => value === watch("password") || "Passwords do not match"})} />
                        {errors.confirmPassword && <span className="text-red-500">Please make sure the password matches.</span>}
                    </div>

                    <div className="field">
                        <div className="ui checkbox">
                        <input type="checkbox" name="agree" tabIndex="0" 
                        {...register("agree", {required: true})} />
                        {errors.agree && <span className="text-red-500">Please agree to terms and conditions.</span>}
                        <label>I agree to the Terms and Conditions</label>
                        </div>
                    </div>
                    <button className="ui button" type="submit">Submit</button>
            
                
                </form>
            </div>


        
        </Modal>
    </div>
)
}


export default SignUp;