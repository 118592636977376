const library = [
    {          
    isbn: 10101,
    book_name: "How to Love",
    image: "https://quill-project-books.s3.ap-southeast-2.amazonaws.com/images/book_how_to_love_TNH.jpg",
    url: "how-to-love",
    description: 'The most popular book in the "How To" series: advice, practices, and food for thought from a Zen Master on our most universal emotion. Nhat Hanh brings his signature clarity, compassion, and humor to the thorny question of how to love. He distills one of our strongest emotions down to four essentials: you can only love another when you feel true love for yourself; love is understanding; understanding brings compassion; deep listening and loving speech are key ways of showing our love.'
    },
    {          
    isbn: 10102,
    book_name: "Karmic Management",
    image: "https://quill-project-books.s3.ap-southeast-2.amazonaws.com/images/book_karmic-management.jpeg",
    url: "karmic-management",
    description: "KARMIC MANAGEMENT is a little book with a revolutionary message. It turns traditional business mentality on its head by stating simply that helping others become successful—suppliers, customers, even competitors—is the real key to success in life as well as in business.",
    }
  ];
  
  
  
  export default library;
  