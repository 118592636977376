import React from 'react';
import ReactDOM from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'semantic-ui-css/semantic.min.css'
import './index.css';

import App from './App';




ReactDOM.createRoot(document.getElementById("root")).render(
	<App />,
	)