import React, { useState } from 'react';
import LogIn from '../01-LogIn/LogIn';
import SignUp from '../01-LogIn/SignUp';
import Navbar from "../00-NavBar/Navbar";




function Header() {

	// Temp Test.
	const user = null;
	  
	// Open SignUp Modal.
	const [showSignUp, setShowSignUp] = useState(false);
    const openSignUp = () => setShowSignUp(true);
    const closeSignUp = () => setShowSignUp(false);

	// Open LogIn Modal.
	const [showLogIn, setShowLogIn] = useState(false);
    const openLogIn = () => setShowLogIn(true);
    const closeLogIn = () => setShowLogIn(false);





	
	return (
		<div className="pusher"  style={{backgroundColor:"#FFC60B"}}>

			<div className="ui inverted vertical masthead center aligned segment">

				<Navbar openLogIn={openLogIn} openSignUp={openSignUp}/>

				<LogIn 
					showLogIn={showLogIn} openLogIn={openLogIn} closeLogIn={closeLogIn} />

				<SignUp
					showSignUp={showSignUp} openSignUp={openSignUp} closeSignUp={closeSignUp} />

			</div>
		</div>
);
}

export default Header;