import { useState} from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, Segment } from 'semantic-ui-react';

export default function NavbarLg({openLogIn, openSignUp}) {


    const user = null;


    const [activeItem,setactiveItem] = useState("home")
    const handleItemClick = (e, { name }) => setactiveItem(name)


    return (
      <div className="ui container">
        <div className="ui large secondary inverted pointing menu">
        <div>
        <Segment inverted size='mini'>
          <Menu inverted secondary>
            <Menu.Item
              name='home'
              active={activeItem === 'home'}
              onClick={handleItemClick}
              link={true}><NavLink to="/">Home</NavLink></Menu.Item>
            <Menu.Item
              name='library'
              active={activeItem === 'library'}
              onClick={handleItemClick}
              link={true}><NavLink to="/library">Library</NavLink></Menu.Item>
            <Menu.Item
              name='about'
              active={activeItem === 'about'}
              onClick={handleItemClick}
              link={true}><NavLink to="/about">About</NavLink></Menu.Item>
            <Menu.Item
              name='contact'
              active={activeItem === 'contact'}
              onClick={handleItemClick}
              link={true}><NavLink to="/contact">Contact</NavLink></Menu.Item>          
          </Menu>
          </Segment>
          </div>
              { !user ? (						
              <div className="right item">
                <button className="ui inverted button mx-2" 
                onClick={openLogIn}>
                  Log in
                </button>
                <button className="ui inverted button mx-2" 
                onClick={openSignUp}>
                  Sign up
                </button>
              </div>
              )
              : (
              <div className="right item avatar">
                <img src="/images/valance.jpg">
                {/* user?.result?.username?.charAt(0)?.toUpperCase()   */}
                </img>
              </div>
              )
              }
          </div>
      </div>
    )
  }

