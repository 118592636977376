import React from 'react';
import HeroHome from './HeroHome';
//import partners from '../shared/partner';

function Home() {
  return (

    <div>
    <HeroHome />


    <div className="ui raised very padded text container segment" style={{margin:'80px'}}>

      <h3 className="ui header">
        Home
      </h3>
      <p>
        In building progress...
      </p>

        {/* <Partner partner={partners} />*/}      

    </div>
    </div>
  );
}

export default Home;