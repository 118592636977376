import React from 'react';

function BookCard (props) {

	return (

		<div className="ui raised very padded text container segment" style={{marginTop:'80px'}}>
			
			<div className="ui grid stackable">
				<div className="row">
					<div className="eight wide right floated column">
						<a className="" href={'/book#' + props.book_url}>
						<img src={props.book_img} alt={props.book_img} width="200"/>
						</a>
					</div>

					<div className="eight wide column">
						<a className="" href={'/book#' + props.book_url}>
						<h1 className="ui header">
						{props.book_name}
						</h1>
						</a>
						<p>
						{props.book_description}
						</p>
					</div>

				</div>
			</div>
			
			{/*<div className="row">
				<div className="spacer"></div>
				<button className="ui button">Add comment.</button>
			</div>*/}
		</div>


		)
}

export default BookCard;