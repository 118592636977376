import React from 'react';









function HeroHome () {

	return (
    <div className="pusher" style={{ backgroundColor:"#FFC60B", padding:"100px" }}>

          <div className="ui text container">
            <h1 className="ui inverted header">
              Quill Project
            </h1>
            <h2>
              Welcome to Quill Project, a website to find easy-to-read non-fiction books, which have simplified texts created by AI technology.               
            </h2>
            <h2>
              Enjoy your journey!
            </h2>
            <div className="ui huge primary button">
              <a className="ui" href='/library' style={{color:"white"}}>
              Get Started 
              <i className="right arrow icon"></i>
              </a>
            </div>
          </div>

        </div>


		)
}

export default HeroHome;