import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';





function LogIn ({ showLogIn, openLogIn, closeLogIn }) {

    return (
    <div className="ui container">
        <Modal size="mini" className="m-auto" centered={true}
        style={{maxHeight:"310px", left:"auto", right:"auto", top:"auto", bottom:"auto"}}
        open={showLogIn} onOpen={openLogIn} onClose={closeLogIn}>
            <div className="header">
                Log in
            </div>
            <div className="content">
                <form className="ui form">
                    <div className="field">
                        <label>Email</label>
                        <input type="email" name="email" placeholder="Email" id="email"/>
                    </div>
                    <div className="field">
                        <label>Password</label>
                        <input type="password" name="password" placeholder="Password" id="password"/>
                    </div>
                    <button className="ui button" type="submit">Submit</button>
                </form>
            </div>
        </Modal>
    </div>





    )
}

export default LogIn;